import { useState, useCallback, useEffect } from 'react';
import { useRecipientsApi } from '../recipients-api';
import { ExtendedRecipientInformation } from '../../types/recipients.types';
import { Recipient } from 'charlie-workflows/types';

export type UseRecipientsHook = () => {
	recipients: ExtendedRecipientInformation[] | null;
	updateList: () => void;
	save: (recipient: Recipient, sessionToAttach?: string) => void;
	update: (recipientId: string, metadata: Partial<Recipient>) => void;
	deleteRecipient: (recipientId: string) => void;
};

export const useRecipients: UseRecipientsHook = () => {
	const {
		getRecipients,
		save,
		update,
		delete: deleteRecipient
	} = useRecipientsApi();
	const [recipients, setRecipients] = useState<
		ExtendedRecipientInformation[] | null
	>(null);

	const updateList = useCallback(() => {
		getRecipients().subscribe((data) => {
			setRecipients(data);
		});
	}, [getRecipients]);

	useEffect(() => {
		updateList();
	}, [updateList]);

	const callSave = useCallback(
		(recipient: Recipient, sessionToAttach?: string) => {
			save(recipient, sessionToAttach).subscribe(() => {
				updateList();
			});
		},
		[updateList, save]
	);

	const callUpdate = useCallback(
		(recipientId: string, metadata: Partial<Recipient>) => {
			update(recipientId, metadata).subscribe(() => {
				updateList();
			});
		},
		[update, updateList]
	);

	const callDelete = useCallback(
		(recipientId: string) => {
			deleteRecipient(recipientId).subscribe(() => {
				updateList();
			});
		},
		[deleteRecipient, updateList]
	);

	return {
		recipients,
		updateList,
		save: callSave,
		update: callUpdate,
		deleteRecipient: callDelete
	};
};
