import { RecipientBubble } from '@/src/shared/ui-kit/components/recipient-bubble';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { ExtendedRecipientInformation } from 'ollie-common/types/recipients.types';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import styles from './signed-in-gifts-introduction.module.scss';

export interface ISignedInGiftsIntroductionProps {
  recipients: ExtendedRecipientInformation[];
  onPressRecipient: (recipient?: ExtendedRecipientInformation) => void;
}

export const SignedInGiftsIntroduction: React.FC<
  ISignedInGiftsIntroductionProps
> = ({ recipients, onPressRecipient }) => {
  const originalListRef = useRef<HTMLUListElement>(null);
  const [isLeftArrow, setIsLeftArrow] = useState<boolean>(false);
  const [isRightArrow, setIsRightArrow] = useState<boolean>(true);

  const sortedRecipients = recipients?.sort((a, b) =>
    (a.name || '').localeCompare(b.name || ''),
  );

  const renderRecipient = (recipient: ExtendedRecipientInformation) => (
    <li
      key={recipient.id}
      className={styles.bubbleWrapper}
      onClick={() => {
        onPressRecipient(recipient);
      }}
    >
      <RecipientBubble recipient={recipient} attached={true} showArrow>
        <span className={styles.recipientSessionsCounter}>
          {recipient.sessions?.length} search
          {recipient.sessions?.length === 1 ? '' : 'es'}
        </span>
      </RecipientBubble>
    </li>
  );

  const offset = 214.4;

  const handlePrevSlide = (event: MouseEvent) => {
    const scrollEl = originalListRef?.current;
    if (!scrollEl) {
      return;
    }

    if (scrollEl?.scrollLeft < offset) {
      originalListRef.current.scroll({
        left: 0,
        behavior: 'smooth',
      });
    } else {
      originalListRef.current.scroll({
        left: scrollEl?.scrollLeft - offset,
        behavior: 'smooth',
      });
    }
  };

  const handleNextSlide = (event: MouseEvent) => {
    const scrollEl = originalListRef?.current;

    if (!scrollEl) {
      return;
    }

    const value = scrollEl?.scrollLeft + offset;
    originalListRef.current.scroll({
      left: value,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    let options = {
      rootMargin: '0px',
      threshold: 1,
    };

    const firstChild = Array.from(originalListRef.current?.children || []).find(
      (el) => el.tagName.toLowerCase() === 'li',
    );
    const lastChild = Array.from(originalListRef.current?.children || [])
      .reverse()
      .find((el) => el.tagName.toLowerCase() === 'li');

    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.every((entry) => {
        if (entry.target === firstChild) {
          setIsLeftArrow(!entry.isIntersecting);
        }

        if (entry.target === lastChild) {
          setIsRightArrow(!entry.isIntersecting);
        }
      });
    };

    let firstChildObs = new IntersectionObserver(callback, options);
    let lastChildObs = new IntersectionObserver(callback, options);
    if (originalListRef.current && firstChild) {
      firstChildObs.observe(firstChild);
    }

    if (originalListRef.current && lastChild) {
      lastChildObs.observe(lastChild);
    }
  }, [originalListRef, recipients]);

  return (
    <section className={styles.container}>
      <div className={styles.savedRecipientsHeader}>
        <span>My saved recipients ({recipients.length})</span>
      </div>

      {recipients.length ? (
        <div className={styles.listWrappers}>
          <ul className={styles.recipientsList} ref={originalListRef}>
            {isLeftArrow ? (
              <button
                className={`${styles.arrowControl} ${styles.arrowControlPrev}`}
                onClick={handlePrevSlide}
              >
                <FaChevronLeft />
              </button>
            ) : null}

            {sortedRecipients.map(renderRecipient)}
            {isRightArrow ? (
              <button
                className={`${styles.arrowControl} ${styles.arrowControlNext}`}
                onClick={handleNextSlide}
              >
                <FaChevronRight />
              </button>
            ) : null}
          </ul>
        </div>
      ) : null}

      <h2 className={styles.newRecipientText}>Or a new recipient?</h2>
    </section>
  );
};
