import { ReactNode, memo, useEffect, useState } from 'react';
import styles from './typewriter.module.scss';

export interface ITypewriterProps {
  className?: string;
  isCaretVisible?: boolean;
  message: string;
  delay?: number;
  children?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  onComplete?: () => void;
}

function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const Typewriter: React.FC<ITypewriterProps> = memo(function Typewriter({
  className,
  isCaretVisible = true,
  message,
  delay = 0,
  prefix,
  suffix,
  onComplete,
  children,
}) {
  const [introductionText, setIntroductionText] = useState<string>('');

  useEffect(() => {
    setIntroductionText('');
  }, [message]);

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        const offset = introductionText.length;

        if (offset < message.length) {
          const nextStringCount = randomIntFromInterval(4, 10);
          const substring = message.substring(offset, offset + nextStringCount);
          setIntroductionText((value) => value + substring);
        } else {
          onComplete?.();
        }
      },
      introductionText.length
        ? randomIntFromInterval(100, 300)
        : delay + randomIntFromInterval(100, 300),
    );

    return () => {
      clearTimeout(timeout);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [introductionText, message, onComplete]);

  return (
    <div className={`${className || ''} ${styles.messageContent}`}>
      <p className={`typewriterText ${styles.fakeIntroductionText}`}>
        {message.length ? prefix : null}
        {message}
        {suffix || null}
      </p>

      {children}

      <div className={styles.typewriterContainer}>
        <p className={`typewriterText ${styles.introductionText}`}>
          {introductionText.length ? prefix : null}
          {introductionText}
          {suffix || null}
          {isCaretVisible ? (
            <span
              className={`caret ${
                introductionText.length !== message.length
                  ? styles.animated
                  : ''
              }`}
            >
              |
            </span>
          ) : null}
        </p>
      </div>
    </div>
  );
});
