/* eslint-disable @next/next/no-img-element */
import { useUser } from '@auth0/nextjs-auth0/client';
import { RecipientEntity } from 'charlie-workflows/contracts';
import { motion } from 'framer-motion';
import { NextPage } from 'next';
import { useCallback, useEffect, useState } from 'react';

import { Footer } from '@/src/shared/ui-kit/components/footer';
import { useCreateChat } from '@/src/shared/hooks/use-create-chat.hook';
import { Loader } from '@/src/shared/ui-kit/components/loader';
import { MessageInput } from '@/src/shared/ui-kit/components/message-input';
import { useSessions } from 'ollie-common/hooks/sessions';
import { useRecipients } from 'ollie-common/hooks/recipients';
import {
  SupportedFlags,
  useFeatureFlags,
} from 'ollie-common/hooks/feature-flags';
import { useAnalytics } from 'ollie-common/hooks/analytics';

import { useModal } from '@/src/services/modal';
import { EditableItem } from '@/src/shared/ui-kit/components/editable-item/editable-item.component';
import { NavigationContainer } from '@/src/shared/ui-kit/components/navigation-container/navigation-container.component';

import {
  ISmartRecipientViewModalProps,
  SmartRecipientViewModal,
} from '../../modals/recipient-view-modal/recipient-view-modal.component';
import { GiftsIntroduction } from './components/gifts-introduction';
import { SignedInGiftsIntroduction } from './components/signed-in-gifts-introduction/signed-in-gifts-introduction.component';
import styles from './gifts-landing-page.module.scss';

export const GiftsLandingPage: NextPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { sessions, getSessions, deleteSession, updateSession } = useSessions();
  const { getFlagValueForFeature } = useFeatureFlags();
  const { recipients, updateList } = useRecipients();
  const { trackEvent } = useAnalytics();

  const { user } = useUser();
  const isUserAuthenticated = Boolean(user);
  const isRecipientsFlagEnabled = getFlagValueForFeature(
    SupportedFlags.SavedRecipients,
  );

  const [isMobile, setIsMobile] = useState(false);
  const [messageInput, setMessageInput] = useState<string>('');

  const { open, close: closeSmartRecipientModal } =
    useModal<ISmartRecipientViewModalProps>(SmartRecipientViewModal);

  const { createChat, isChatCreationLoading } = useCreateChat(() => {
    setIsLoading(true);
    getSessions().finally(() => setIsLoading(false));
    try {
      closeSmartRecipientModal();
    } catch {}
  });

  const onSubmitMessage = useCallback(
    (initialMessage: string, image?: File) => {
      createChat(
        initialMessage,
        {
          topic: 'gifting',
        },
        { file: image },
      );
    },
    [createChat],
  );

  const onRecipientClick = useCallback(
    (recipient: RecipientEntity | undefined) => {
      if (recipient) {
        open({
          recipientId: recipient.id,
          onClose: closeSmartRecipientModal,
          onUpdateRecipient: updateList,
          onStartNewChat: () => {
            createChat(undefined, {
              topic: 'gifting',
              recipientId: recipient.id,
            });

            trackEvent(
              'started_search_for_recipient',
              {
                recipientId: recipient.id,
              },
              'conversion',
            );
          },
        });
      }
    },
    [createChat, open, closeSmartRecipientModal, updateList, trackEvent],
  );

  let initialMessage = user
    ? `Hey ${
        (user.name || 'User').split(' ')?.[0]
      }, welcome back! 👋 Who are you looking to get a gift for today?`
    : 'Hey, I’m Ollie the AI 👋. I’ll help you find thoughtful gifts with ease.';
  let subhead = '';

  return (
    <div className={styles.main}>
      <NavigationContainer className={styles.container} onMobile={setIsMobile}>
        <div className={styles.fullscreenContainer}>
          <div className={styles.sectionsContainer}>
            <section
              className={`${styles.introductionSection} ${
                recipients?.length ? styles.recipientIntroductionSection : ''
              } ${user ? styles.introductionSectionForGuest : ''}`}
            >
              <div className={styles.introductionSectionContent}>
                <div className={styles.messageBubble}>
                  <div className={styles.ollieMessageText}>
                    <h1 className="titleText">
                      {initialMessage}
                      <br />
                      {subhead && subhead.length ? (
                        <span className={styles.subhead}>{subhead}</span>
                      ) : null}
                    </h1>
                  </div>

                  {isChatCreationLoading ? (
                    <div className={styles.loadingBlock}>
                      <span>Wait a second, a chat is starting...</span>
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {isUserAuthenticated && isRecipientsFlagEnabled ? (
                        <>
                          {recipients && recipients.length ? (
                            <SignedInGiftsIntroduction
                              recipients={recipients.map((recipient) => ({
                                ...recipient,
                                sessions: (sessions || []).filter(
                                  (search) =>
                                    search.recipientId === recipient.id,
                                ),
                              }))}
                              onPressRecipient={onRecipientClick}
                            />
                          ) : null}
                        </>
                      ) : (
                        <GiftsIntroduction />
                      )}

                      <motion.div
                        className={styles.messageInputLayout}
                        initial={{ translateY: -40, opacity: 0 }}
                        animate={{ translateY: 0, opacity: 1 }}
                        exit={{ translateY: 40, opacity: 0 }}
                      >
                        <MessageInput
                          className={styles.messageInput}
                          value={messageInput}
                          autofocus={false}
                          onChange={(e) => setMessageInput(e.target.value)}
                          imageAttachingEnabled={false}
                          placeholder={
                            isMobile
                              ? 'Gift for my friend’s birthday'
                              : 'I need gift ideas for my wife for our 10th anniversary '
                          }
                          onSend={onSubmitMessage}
                        />
                      </motion.div>

                      {/* {!isUserAuthenticated ? (
                        <div className={styles.ctaContainer}>
                          <CtaBanner />
                        </div>
                      ) : null} */}
                    </>
                  )}
                </div>
              </div>

              {/* <p className={styles.infoMessage}>
                Try{' '}
                <Link className={styles.infoLink} href="/for-me">
                  Ollie for Me
                </Link>
                , your <i>personal</i> AI shopping assistant <span>Beta</span>
              </p> */}
            </section>

            {isMobile ? (
              isUserAuthenticated ? (
                <section className={styles.savedGifts}>
                  <h3 className={`${styles.savedGiftsTitle}`}>
                    Saved Gift Searches{' '}
                    {sessions?.length ? `(${sessions.length})` : ''}
                  </h3>
                  <div className={styles.listWrapper}>
                    <ul className={styles.list}>
                      {isLoading
                        ? [null, null, null].map((thread, index) => (
                            <EditableItem
                              isSkeleton
                              className={styles.editableItem}
                              key={index}
                            />
                          ))
                        : (sessions || []).map((thread) => (
                            <EditableItem
                              className={styles.savedGiftsMobileItem}
                              mode="menu"
                              key={thread.id}
                              id={thread.id}
                              value={thread.name || 'Unnamed session'}
                              onSave={(value) =>
                                updateSession(thread.id, value)
                              }
                              onRemove={() => deleteSession(thread.id)}
                            />
                          ))}
                    </ul>
                  </div>
                </section>
              ) : null
            ) : null}

            {/* {!user ? (
              <section className={styles.cases}>
                <Ticker duration={50}>
                  {cases.map(
                    ({ forWho, foundWhat, image, who, isMultiple }) => (
                      <div key={who} className={styles.case}>
                        <div className={styles.image}>
                          <Image
                            alt="Use case"
                            src={image}
                            fill
                            quality={100}
                          />
                        </div>
                        <span className={styles.content}>
                          <b>{who}</b> found {!isMultiple ? 'a' : ''}{' '}
                          <b>{foundWhat}</b> {forWho}
                        </span>
                      </div>
                    ),
                  )}
                </Ticker>
              </section>
            ) : null} */}
          </div>
        </div>
      </NavigationContainer>

      <Footer className={styles.footer} />
    </div>
  );
};
